<template>
    <v-row
        class="acceptConditions"
    >
        <v-col cols="12">
            <h1>Betingelser for brug af Kundeportalen:</h1>
        </v-col>
        <v-col cols="12">
            &bull; Genbrugsstationerne er videoovervåget døgnet rundt
        </v-col>
        <v-col cols="12">
            &bull; Vi gemmer data (videoovervågning og portregistreringer) til internt brug i op til 1 måned som dokumentation for evt. overtrædelse af genbrugsstationernes ordensreglement. Se ordensreglementet.
        </v-col>
        <v-col cols="12">
            &bull; Overtrædelse af genbrugsstationernes ordensreglement kan medføre opkrævning af gebyr samt karantæne i min. 3 måneder. Ved større misbrug af ordningen kan en udelukkelse komme på tale
        </v-col>
        <v-col cols="12">
            &bull; Du kan ikke aflevere eternit-tagplader. Farligt affald kan kun afleveres i mængder, der kan være i de røde miljøkasser. Der forefindes tomme kasser på pladsen.
        </v-col>
        <v-col cols="12">
            &bull; Af hensyn til din sikkerhed er strømmen slukket til containerne med komprimatorer. Alle containere, som må benyttes udenfor bemandet åbningstid, er mærket med et gult klistermærke med skriften “8-22” enten på containeren eller på skiltet ved containeren.
        </v-col>
        <v-col cols="12">
            &bull; Kommer du til genbrugsstationen i en erhvervsbil, der er tilmeldt Kundeportalens ordningen, så bliver dit affald kategoriseret som erhvervsaffald
        </v-col>
        <v-col cols="12">
            &bull; Du får adgang til genbrugsstationerne via mobiltelefonnummer eller bilens nummerplade, du melder til ordningen. Evt anonymiserede telefonnumre (f.eks. skjult nummer) skal ophæves for at det virker
        </v-col>
        <v-col cols="12">
            &bull; Du må ikke misbruge din adgang og lukke andre biler/brugere ind.
        </v-col>
        <v-col cols="12">
            &bull; Ved at acceptere betingelserne giver du samtidig tilladelse til, at Ikast-Brande Kommune må sende dig relevante nyheder i forbindelse med Kundeportalen ordningen.
        </v-col>
        <v-col cols="12">
            <h2>Brugen af dine persondata</h2>
        </v-col>
        <v-col cols="12">
            &bull; Ved at benytte Kundeportalen, accepterer du at vi registrerer følgende oplysninger om dig: Navn, telefonnummer, registreringsnumre på dine biler, tidspunkt for indkørsel og bopælskommune
        </v-col>
        <v-col cols="12">
            &bull; Disse data bruges til at <br>
            &bull; sikre din adgang til genbrugsstationerne vha. mobilnummer og evt bil registreringsnummer <br>
            &bull; kommunikere med dig i tilfælde af driftsforhold eller overtrædelse af reglementet
        </v-col>
        <v-col cols="12">
            &bull; Sletning eller ændring af registrerede oplysninger kan foretages ved at logge ind med MitID igen og foretage sletning eller ændring.
        </v-col>
        <v-col cols="12">
            <v-card
                color="#ECECEC"
                style="align-items: center;"
            >
                <v-checkbox
                    v-model="checkboxAccept"
                    label="Jeg accepterer betingelserne for at bruge genbrugsstationerne i Ikast-Brande Kommune."
                />
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-btn
                :disabled="!checkboxAccept"
                color="#75ad40"
                @click="$emit('acceptConditions')"
            >
                Accepter
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>

export default {
    data() {
        return {
            checkboxAccept: false
        }
    }
}

</script>