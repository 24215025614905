<template>
    <v-row
        class="acceptConditions"
    >
        <v-col cols="12">
            Velkommen til Hillerød Forsynings genbrugsstation. <br>
                Her er du velkommen - også uden for normal åbningstid. <br> 
                Den ubemandede grenbrugsstation har åben alle ugens dage kl. 06.00-09.00 og kl. 17.00-20.00.
            
        </v-col>
        
        <v-col cols="12">
            Vær opmærksom på, at du i ubemandet åbningstid ikke kan aflevere:
        </v-col>
        <v-col cols="12">
            &bull; Farligt affald <br>
            &bull; Elektronik/hårde hvidevarer <br>
            &bull; Pap <br>
            &bull; Rest efter sortering <br>
        </v-col>
        <v-col cols="12">
            Sørg for altid for at holde området rent og pænt og aflever affaldet i de korrekte containere. Aflæsning på jorden og ved containeren er ikke tilladt.
        </v-col>
        <v-col cols="12">
            Her kan du læse mere om vores genbrugsstation og finde regler information om sikkerhed: <a href="https://www.hfors.dk/ubemandet" target="_blank">https://www.hfors.dk/ubemandet</a>
        </v-col>
        <v-col cols="12">
            
                <v-checkbox
                    v-model="checkboxAccept"
                    label="Jeg accepterer betingelserne."
                />
            
        </v-col>
        <v-col cols="12">
            <v-btn
                :disabled="!checkboxAccept"
                color="#75ad40"
                @click="$emit('acceptConditions')"
            >
                Accepter
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>

export default {
    data() {
        return {
            checkboxAccept: false
        }
    }
}

</script>