<template>
    <v-row
        class="acceptConditions"
    >
        <v-col cols="12">
            <h1>Betingelser for brug af Kundeportalen:</h1>
        </v-col>
        
        <v-col cols="12">
            <h2>Brugen af dine persondata</h2>
        </v-col>
        <v-col cols="12">
            &bull; Ved at benytte Kundeportalen, accepterer du at vi registrerer følgende oplysninger om dig: Navn, telefonnummer, registreringsnumre på dine biler, tidspunkt for indkørsel og bopælskommune
        </v-col>
        <v-col cols="12">
            &bull; Disse data bruges til at <br>
            &bull; sikre din adgang til genbrugspladserne vha. mobilnummer og evt bil registreringsnummer <br>
            &bull; kommunikere med dig i tilfælde af driftsforhold eller overtrædelse af reglementet
        </v-col>
        <v-col cols="12">
            &bull; Sletning eller ændring af registrerede oplysninger kan foretages ved at logge ind med MitID igen og foretage sletning eller ændring.
        </v-col>
        <v-col cols="12">
            <v-card
                color="#ECECEC"
                style="align-items: center;"
            >
                <v-checkbox
                    v-model="checkboxAccept"
                    label="Jeg accepterer betingelserne."
                />
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-btn
                :disabled="!checkboxAccept"
                color="#75ad40"
                @click="$emit('acceptConditions')"
            >
                Accepter
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>

export default {
    data() {
        return {
            checkboxAccept: false
        }
    }
}

</script>